import { SHARED_APIS } from "configs";
import { createContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { fetchMultipleAPIs } from "utils";

export const SharedAPIDataContext = createContext();

export function SharedAPIDataContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [staticData, _setStaticData] = useState({
    settings: [],
    languages: [],
    // pages: [],
  });

  const value = useMemo(
    () => ({
      ...staticData,
    }),
    [staticData]
  );

  useEffect(() => {
    fetchMultipleAPIs({
      endpoints: SHARED_APIS,
      onSuccess: (data) => {
        _setStaticData(data);
        setLoading(false);
      },
    });
  }, []);

  // useEffect(() => {
  //   console.log("staticData", staticData);
  // }, [staticData]);

  if (loading) return false;
  return (
    <SharedAPIDataContext.Provider value={value}>
      {children}
    </SharedAPIDataContext.Provider>
  );
}

import { DEFAULT_LOCALE } from "configs";
// import { useSharedAPIDataContext } from "hooks/useSharedAPIDataContext";
import { useCallback, useMemo, useState, useContext, createContext, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Create Context
const LanguageContext = createContext({});

// Create Context Provider
export const LanguageContextProvider = ({ children }) => {

  // const { languages } = useSharedAPIDataContext();

  const navigate = useNavigate();
  const params = useParams();
  const languageFromUrl = params.lang;
  const languageFromLocalStorage = localStorage.getItem("eduzad-language");
  const [language, setLanguage] = useState(
    languageFromUrl || languageFromLocalStorage || DEFAULT_LOCALE
  );

  const handleSwitchLanguage = useCallback((newLanguage) => {
    // switch between the 2 Available Languages
    // let newLanguage = LANGUAGES.filter((lng) => lng !== language)[0];
    // let newLanguage = languages.map(i => i.code).filter((lng) => lng !== language)[0];
    navigate(`/${newLanguage}`);
    setLanguage(newLanguage);
    localStorage.setItem("eduzad-language", newLanguage);

    window.location.reload();
  }, [navigate]); // language, navigate, languages

  // useEffect(() => {
  //   if (language === DEFAULT_LOCALE) {
  //     document.documentElement.setAttribute("dir", languages.map(i => i.direction).filter((dir) => dir !== DEFAULT_LAYOUT_DIRECTION)[0]);
  //     document.documentElement.setAttribute("lang", languages.map(i => i.code).filter((lng) => lng !== language)[0]);
  //   } else {
  //     document.documentElement.setAttribute("dir", languages.map(i => i.direction).filter((dir) => dir === DEFAULT_LAYOUT_DIRECTION)[0]);
  //     document.documentElement.setAttribute("lang", languages.map(i => i.code).filter((lng) => lng === language)[0]);
  //   }
  //   // if (language === "ar") {
  //   //   document.documentElement.setAttribute("dir", "rtl");
  //   //   document.documentElement.setAttribute("lang", "ar");
  //   // } else {
  //   //   document.documentElement.setAttribute("dir", "ltr");
  //   //   document.documentElement.setAttribute("lang", "en");
  //   // }
  // }, [language, languages]);

  useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", language );
    document.documentElement.setAttribute("dir", language === 'ar' ? 'rtl' : 'ltr');
  }, [language]);

  const value = useMemo(
    () => ({
      language,
      handleSwitchLanguage,
    }),
    [language, handleSwitchLanguage]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// create a hook to use the Context
export const useLanguageContext = () => useContext(LanguageContext);

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar, Nav, Button, Dropdown } from "react-bootstrap";
import { useLanguage } from "hooks";
import "./Header.scss";
import { useSharedAPIDataContext } from "hooks/useSharedAPIDataContext";
import { STORAGE_URL } from "configs";

function Header() {
  const { language, translation, handleSwitchLanguage } = useLanguage();

  const { languages, settings } = useSharedAPIDataContext();

  const [isScrolled, setIsScrolled] = useState(false);

  const navLinks = [
    {
      title: translation.home,
      link: "#home",
    },
    {
      title: translation.about,
      link: "#about",
    },
    {
      title: translation.plans,
      link: "#plans",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="header" className={`py-3 ${isScrolled ? 'fixed-header' : ''}`}>
      <Container>
        <Row>
          <Col xs={4} sm={4} md={3} lg={2} xl={2}>
            {language === "ar" ? (
              isScrolled ? settings && settings.header_secondary_icon && <img
                src={STORAGE_URL + settings.header_secondary_icon}
                alt="secondary Logo"
                className="logo-img"
              /> : settings && settings.header_icon && <img
                src={STORAGE_URL + settings.header_icon}
                alt="logo"
                className="logo-img"
              />
            ) : (
              isScrolled ? settings && settings.header_secondary_icon && <img
                src={STORAGE_URL + settings.header_secondary_icon}
                alt="secondary Logo"
                className="logo-img"
              /> : settings && settings.header_icon && <img
                src={STORAGE_URL + settings.header_icon}
                alt="logo"
                className="logo-img"
              />
            )}
          </Col>
          <Col
            xs={8}
            sm={8}
            md={9}
            lg={10}
            xl={10}
            className="align-self-center"
          >
            <div className="contant">
              <Row className="align-items-center">
                <Col xs={12} lg={10} xl={11}>
                  <div className="header-navigation">
                    <Navbar
                      bg="transparent"
                      expand="lg"
                      className="navbar justify-content-end"
                    >
                      <Navbar.Toggle
                        color="white"
                        aria-controls="basic-navbar-nav"
                      />
                      <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="text-end px-3 py-2 rounded-3 justify-content-end"
                      >
                        <Nav className="text-start">
                          {navLinks.map((item, idx) => (
                            <Nav.Link key={idx} href={item.link} className="hover">{item.title}</Nav.Link>
                          ))}
                        </Nav>
                        <div className="d-block d-lg-none mt-3 text-start">
                          {languages && languages.length > 2 && (
                            <Dropdown className="header-dropdown-lang">
                              <Dropdown.Toggle className="w-100" variant={isScrolled ? "star" : "primary"} id="dropdown-basic">
                                {languages.find((l) => l.code === language).name}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {languages
                                  .filter((l) => l.code !== language)
                                  .map((item, idx) => (
                                    <Dropdown.Item
                                      key={idx}
                                      onClick={() => handleSwitchLanguage(item.code)}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                          {languages && languages.length === 2 && (
                            <Button
                              onClick={() => handleSwitchLanguage(languages.find((l) => l.code !== language).code)}
                              variant={isScrolled ? "star" : "primary"}
                              className="w-100"
                            >
                              <div>
                                <h5 className="text-white">
                                  {languages.find((l) => l.code !== language).name}
                                </h5>
                              </div>
                            </Button>
                          )}
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </Col>
                <Col xs={12} lg={2} xl={1} className="lang d-none d-lg-block">
                  <div className="langButton">
                    {languages && languages.length > 2 && (
                      <Dropdown className="header-dropdown-lang">
                        <Dropdown.Toggle variant={isScrolled ? "star" : "primary"} id="dropdown-basic">
                          {/* {localeConfig.name} */}
                          {languages.find((l) => l.code === language).name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {languages
                            .filter((l) => l.code !== language)
                            .map((item, idx) => (
                              <Dropdown.Item
                                key={idx}
                                onClick={() => handleSwitchLanguage(item.code)}
                              >
                                {item.name}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    {languages && languages.length === 2 && (
                      <Button
                        onClick={() => handleSwitchLanguage(languages.find((l) => l.code !== language).code)}
                        variant={isScrolled ? "star" : "primary"}
                        className="rounded-5"
                      >
                        <div>
                          <h5 className={isScrolled ? "text-black" : "text-white"}>
                            {languages.find((l) => l.code !== language).name}
                          </h5>
                        </div>
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default Header;

import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import './AnimationFade.scss';

function AnimationFade({ children }) {
  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, y: 150 }
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  const [scrollDirection, setScrollDirection] = useState('down');

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;

      if (currentPosition > lastScrollPosition) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      lastScrollPosition = currentPosition;
    };

    let lastScrollPosition = window.scrollY;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inView && scrollDirection === 'down') {
      control.start("visible");
    } else if (scrollDirection === 'up') {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView, scrollDirection]);

  return (
    <div id='fade-animation-style'>
      <motion.div
        className="box"
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default AnimationFade;
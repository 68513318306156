import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { LanguageContextProvider } from "contexts";
import { Home } from "pages";
import "assets/styles/App.scss";

const Container = ({ children }) => {
  return <LanguageContextProvider>{children}</LanguageContextProvider>;
};

function App() {
  return (
    <Fragment>
      <Routes>
      <Route
          path="/"
          element={
            <Container>
              <Home />
            </Container>
          }
        />
        <Route
          path="/:lang/"
          element={
            <Container>
              <Home />
            </Container>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;

import axios from "axios";
import { API_TOKEN, API_URL, DEFAULT_LOCALE } from "../configs";

function axiosInstance(config) {
  return axios.create(
    config || {
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        Token: API_TOKEN,
      },
    }
  );
}

export default async function apiService({
  signal,
  url,
  baseURL,
  method = "GET",
  payload,
  locale,
  language,
  parser,
  headers,
}) {
  let data = null;
  let error = null;
  let statusCode = -1;

  try {
    const response = await axiosInstance().request({
      signal,
      method,
      ...(method === "GET" && { params: payload }),
      ...(method === "POST" && { data: payload }),
      url,
      baseURL,
      headers: {
        Language: language || locale || DEFAULT_LOCALE,
        ...headers,
      },
    });
    if (parser) {
      data = parser(response.data);
      statusCode = response.status;
    } else {
      data = response.data.data;
      statusCode = response.data.status;
    }
  } catch (err) {
    error = err.response.data;
    statusCode = err.response.status;
  }
  return { data, error, statusCode };
}

import React from "react";
import { Button, Card, } from "react-bootstrap";
import "./CustomCard.scss";
import { AiFillCheckCircle } from "react-icons/ai";
import { useLanguage } from "hooks";

function CustomCard({ bg, description, color, name, price, per }) {
  const { translation } = useLanguage();

  const stripHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  };

  const descriptionItems = description.split("<li>").filter(Boolean);

  return (
    <div className="custom-card">
      <Card className="custom-card-style rounded-4">
        <div className="text-center mt-5" >
          <div className={`custom-card-details bg-${bg} rounded-circle`}>
            <div className="text-start">
              <h4 className="title">{name}</h4>
              <div>
                <h3 className="price">{price}$</h3>
                <p className="unber-price">{per}</p>
              </div>
            </div>
          </div>
        </div>
        <Card.Body className="custom-card-body">
          {descriptionItems.slice(1).map((item, index) => (
            <div key={index} className="d-flex align-items-center gap-2 ">
              <AiFillCheckCircle className={color} />
              {stripHTMLTags(item)}
            </div>
          ))}
        </Card.Body>
        <div>
          <Button
            className="custom-card-button rounded-5"
          >
            <span>
              {translation.buyNow}
            </span>
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default CustomCard;

import { API_URL } from "configs";
import { useCallback, useMemo, useRef, useState } from "react";
import { apiService } from "services";
import useLanguage from "./useLanguage";

function getUrl(relative) {
  // eslint-disable-next-line
  const urlExpression =
    "https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)";
  const regex = new RegExp(urlExpression);

  if (relative.match(regex)) {
    return relative;
  }

  var mainURL = API_URL;
  if (mainURL === undefined) return "";
  if (mainURL.charAt(mainURL.length - 1) !== "/") mainURL += "/";

  if (relative.length > 0 && relative.charAt(0) === "/")
    relative = relative.substring(1, relative.length);

  return mainURL + relative;
}

export function useApiService(
  path,
  method,
  initialLoadingState = true,
  resultParser
) {
  // ** State
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [loading, setLoading] = useState(initialLoadingState);

  // ** Vars
  const controllerRef = useRef(new AbortController());

  // ** Hooks
  const { language } = useLanguage();
  // ** Functions
  const cancel = useMemo(() => {
    return controllerRef.current.abort;
  }, []);

  const fetchData = useCallback(
    async (args) => {
      const {
        payload,
        resultParser: customParser,
        onSuccess,
        onError,
        onDone,
        headers,
      } = args || {};
      const parser = customParser || resultParser;

      // if (loading) return;

      setLoading(true);

      const staticURL = getUrl(path);

      try {
        const { data, error, statusCode } = await apiService({
          signal: controllerRef.current.signal,
          method,
          url: staticURL,
          payload,
          language,
          parser,
          headers: {
            ...headers,
          },
        });

        setStatusCode(statusCode);

        if (error) {
          if (onError) await onError(error);
          setError(error);
        } else {
          if (onSuccess) await onSuccess(data);
          setResult(data);
          setError(null);
        }
      } catch (err) {
        console.log("err", err);
        setResult({});
        setError(err);
      } finally {
        if (onDone) await onDone();
      }

      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    result,
    error,
    loading,
    statusCode,
    fetchData,
    cancel,
  };
}

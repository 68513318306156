export const DEFAULT_LAYOUT_DIRECTION = "ltr";
export const DEFAULT_LOCALE = "en";
export const API_URL = process.env.REACT_APP_API_URL;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;
export const SHARED_APIS = [
  {
    method: "GET",
    name: "settings",
    endpoint: "get_settings",
  },
  {
    method: "GET",
    name: "languages",
    endpoint: "getLanguages",
  },
//   {
//     method: "GET",
//     name: "pages",
//     endpoint: "getPages",
//   },
];

// export function getKeyValueFromArray(data =[], key) {
//   if (data.length === 0 || !data) return;
//   return data.find((item) => item.key === key)?.value || "";
// }


export function getKeyValueFromArray(data = [], type, key) {
  if (data.length === 0 || !data) return null;

  for (const item of data) {
    if (item.sort == type) {
      return item[key]
    }
  }

  return null; 
}
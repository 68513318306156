import React, { useState } from "react";
import { AnimationFade, CustomCard, Divider, Layout, WebsiteLoader } from "components";
import { Container, Row, Col, } from "react-bootstrap";
import { useApiGet, useLanguage } from "hooks";
import "./Home.scss";
import 'react-loading-skeleton/dist/skeleton.css'
import { getKeyValueFromArray } from "utils";
import { STORAGE_URL } from "configs";


function Home() {
  const { translation } = useLanguage();

  // ** State
  const [data, setData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [plansData, setPlansData] = useState([]);


  // ** Hooks
  const fetchSlider = useApiGet({
    path: "single_slider_data",
    onSuccess: (res) => {
      setData(res);
    },
  });

  const fetchAbout = useApiGet({
    path: "single_about_data",
    onSuccess: (res) => {
      setAboutData(res);
    },
  });

  const fetchGallery = useApiGet({
    path: "get_gallery_data",
    onSuccess: (res) => {
      setGalleryData(res);
    },
  });

  const fetchPlans = useApiGet({
    path: "get_plan_data",
    onSuccess: (res) => {
      setPlansData(res);
    },
  });


  if (fetchSlider.loading || fetchAbout.loading || fetchGallery.loading || fetchPlans.loading) return <WebsiteLoader />;

  return (
    <Layout>
      <div id="home-page" className="pt-5 position-relative">
        <Container>
          <div id="home" className="position-relative" dir="ltr">
            <Row className="align-items-center gap-5 gap-md-0">
              <Col xs={12} md={5} lg={5} xl={6}>
                <div>
                  <div className="left-circle">
                    <div className="left-circle-div">
                      <div className="left-circle-details" />
                    </div>
                  </div>
                  <AnimationFade>
                    <h1 className="text-center">
                      {data.title}
                      {/* <span>{translation.makeYourSchool}</span><br /><span>{translation.moreDisciplinedAndSafe}</span> */}
                    </h1>
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12} md={7} lg={7} xl={6}>
                <div className="text-center text-lg-end position-relative">
                  <AnimationFade>
                    <img
                      src={STORAGE_URL + data.image} //"/Images/rightImg2-min.png"
                      alt="rightImg"
                      width="100%"
                      height="100%"
                      loading="lazy"
                    />
                    <div className="warrap-top">
                      <div className="warrap-details-top">
                        <div className="warrap-div-top">
                          <img
                            src="/Images/blueCircle.png"
                            alt="blueCircle"
                            className="twist-animation-top"
                          />
                        </div>
                      </div>
                    </div>
                  </AnimationFade>
                  <div className="right-circle">
                    <div className="right-circle-div">
                      <div className="right-circle-details" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div id="about" className="mt-5 position-relative" dir="ltr">
            <Row className="align-items-center">
              <Col xs={12} md={7} lg={6}>
                <div className="left-section text-center text-lg-end position-relative">
                  {/* <div className="left-about-circle">
                    <div className="left-about-circle-div">
                      <div className="left-about-circle-details" />
                    </div>
                  </div> */}
                  <AnimationFade>
                    <div className="warrap">
                      <div className="warrap-details">
                        <div className="warrap-div">
                          <img
                            src="/Images/redCircle.png"
                            alt="redCircle"
                            className="twist-animation"
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      src={STORAGE_URL + aboutData.image}
                      alt="leftImg"
                      width="100%"
                      height="auto"
                      loading="lazy"
                    />
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12} md={5} lg={6}>
                <AnimationFade>
                  <div>
                    <h3 className="about-title">
                      {/* {translation.about} */}
                      {aboutData.title}
                    </h3>
                    <h6 className="about-description mt-2">
                      {/* {translation.aboutParg} */}
                      {aboutData.text}
                    </h6>
                  </div>
                </AnimationFade>
                <div className="right-about-circle">
                  <div className="right-about-circle-div">
                    <div className="right-about-circle-details" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div id="features" className="features">
            <div className="features-right-img" />
            <Row className="align-items-center">
              <Col xs={12} >
                <AnimationFade>
                  <div className="text-center">
                    <h2 className="title-under-about">{translation.bodyTitle}</h2>
                    <h1 className="subtitle-under-about">{translation.bodySubTitle}</h1>
                    <Divider />
                  </div>
                </AnimationFade>
              </Col>
            </Row>
          </div>

          <div className="under-features text-center">
            <Row className="justify-content-center">
              <Col xs={12}>
                <div className="text-center">
                  <AnimationFade>
                    <img
                      src={STORAGE_URL + getKeyValueFromArray(galleryData, "1", 'image')}
                      alt="school"
                      className="school"
                      loading="lazy"
                    />
                  </AnimationFade>
                </div>
              </Col>
              <Col xs={12}>
                <Row className="align-items-center" dir="ltr">
                  <Col xs={12} md={6} xl={4}>
                    <AnimationFade>
                      <div className="first-row-first-img">
                        <h5 className="text1">
                          {/* {translation.cateringSystem} */}
                          {getKeyValueFromArray(galleryData, "2", 'text')}
                        </h5>
                        <img
                          src={STORAGE_URL + getKeyValueFromArray(galleryData, "2", 'image')}
                          alt="Cafeteria"
                          loading="lazy"
                        />
                      </div>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <AnimationFade>
                      <div className="first-row-second-img">
                        <h5 className="text2">
                          {/* {translation.eGates} */}
                          {getKeyValueFromArray(galleryData, "3", 'text')}
                        </h5>
                        <img
                          src="/Images/wifi_2-min.gif"
                          alt="wifi_2"
                          className="wifi"
                          loading="lazy"
                        />
                        <img
                          src={STORAGE_URL + getKeyValueFromArray(galleryData, "3", 'image')}
                          alt="RFID"
                          className="second_img"
                          loading="lazy"
                        />
                        <img
                          src="/Images/wifi_2-min.gif"
                          alt="wifi_2"
                          className="wifi2"
                          loading="lazy"
                        />
                        <h5 className="text3">
                          {/* {translation.studentsTimeAttendance} */}
                          {getKeyValueFromArray(galleryData, "3", 'text2')}
                        </h5>
                      </div>
                    </AnimationFade>
                  </Col>
                  <Col xs={12} md={12} xl={4}>
                    <AnimationFade>
                      <div className="first-row-third-img">
                        <h5 className="text4">
                          {/* {translation.studentsCall} */}
                          {getKeyValueFromArray(galleryData, "4", 'text')}
                        </h5>
                        <img
                          src="/Images/wifi-min.gif"
                          alt="icons-09"
                          className="wifi-car"
                          loading="lazy"
                        />
                        <img
                          src={STORAGE_URL + getKeyValueFromArray(galleryData, "4", 'image')}
                          alt="car"
                          className="img1"
                          loading="lazy"
                        />
                        <img
                          src={STORAGE_URL + getKeyValueFromArray(galleryData, "5", 'image')}
                          alt="School Bus"
                          className="img2"
                          loading="lazy"
                        />
                        <h5 className="text5">
                          {/* {translation.toursTracking} */}
                          {getKeyValueFromArray(galleryData, "5", 'text')}
                        </h5>
                      </div>
                    </AnimationFade>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} xl={10}>
                <AnimationFade>
                  <div className="second-row">
                    <h5 className="text6">
                      {/* {translation.teachersManagement} */}
                      {getKeyValueFromArray(galleryData, "6", 'text')}
                    </h5>
                    <h5 className="text7">
                      {/* {translation.parentsManagement} */}
                      {getKeyValueFromArray(galleryData, "7", 'text')}
                    </h5>
                    <h5 className="text8">
                      {/* {translation.studentsManagement} */}
                      {getKeyValueFromArray(galleryData, "6", 'text2')}
                    </h5>
                    <img
                      src={STORAGE_URL + getKeyValueFromArray(galleryData, "6", 'image')}
                      alt="Managment part"
                      className="family-img"
                      loading="lazy"
                    />
                    <img
                      src={STORAGE_URL + getKeyValueFromArray(galleryData, "7", 'image')}
                      alt="Managment part"
                      className="part-img"
                      loading="lazy"
                    />
                  </div>
                </AnimationFade>
              </Col>
              <Col xs={12} xl={9} className="mt-5 mt-lg-2 mt-xl-5">
                <div className="third-row">
                  <Row className="align-items-end gap-4 gap-md-0">
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text9">
                            {/* {translation.registration} */}
                            {getKeyValueFromArray(galleryData, "8", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "8", 'image')}
                            alt="Managment part"
                            className="row4-image1"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text10">
                            {/* {translation.activitiesEvents} */}
                            {getKeyValueFromArray(galleryData, "9", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "9", 'image')}
                            alt="Managment part"
                            className="row4-image2"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text12">
                            {/* {translation.uniformBooksSales} */}
                            {getKeyValueFromArray(galleryData, "10", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "10", 'image')}
                            alt="Managment part"
                            className="row4-image3"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text11">
                            {/* {translation.financialManagement} */}
                            {getKeyValueFromArray(galleryData, "11", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "11", 'image')}
                            alt="Managment part"
                            className="row4-image4"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} xl={9}>
                <div className="four-row mt-5">
                  <Row className="justify-content-center align-items-end">
                    <Col xs={12} md={6} lg={3} xl={2} >
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text13">
                            {/* {translation.liveClasses} */}
                            {getKeyValueFromArray(galleryData, "12", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "12", 'image')}
                            alt="Managment part"
                            className=""
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text14">
                            {/* {translation.eLearning} */}
                            {getKeyValueFromArray(galleryData, "13", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "13", 'image')}
                            alt="Managment part"
                            className=""
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3} className="mt-5 mt-md-5 mt-lg-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text15">
                            {/* {translation.libraries} */}
                            {getKeyValueFromArray(galleryData, "14", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "14", 'image')}
                            alt="Managment part"
                            className="row5-image3"
                            width="100%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-5 mt-lg-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text16">
                            {/* {translation.examsGrades} */}
                            {getKeyValueFromArray(galleryData, "15", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "15", 'image')}
                            alt="Managment part"
                            className="row5-image4"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={2} className="mt-5 mt-md-5 mt-xl-0">
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text17">
                            {/* {translation.healthcareSystem} */}
                            {getKeyValueFromArray(galleryData, "16", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "16", 'image')}
                            alt="9.png"
                            className="row5-image5"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <div className="five-row mt-5">
                  <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text18">
                            {/* {translation.erpSystem} */}
                            {getKeyValueFromArray(galleryData, "17", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "17", 'image')}
                            alt="10.gif"
                            className=""
                            width="80%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                    <Col xs={12} md={6} lg={3} xl={3}>
                      <AnimationFade>
                        <div className="position-relative">
                          <h5 className="text19">
                            {/* {translation.cloud} */}
                            {getKeyValueFromArray(galleryData, "18", 'text')}
                          </h5>
                          <img
                            src={STORAGE_URL + getKeyValueFromArray(galleryData, "18", 'image')}
                            alt="11.gif"
                            className=""
                            width="80%"
                            height="auto"
                            loading="lazy"
                          />
                        </div>
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={10}>
                <div className="five-row mt-5">
                  <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={6} xl={4}>
                      <AnimationFade>
                        <img
                          src={STORAGE_URL + getKeyValueFromArray(galleryData, "19", 'image')}
                          alt="10.gif"
                          className=""
                          width="100%"
                          height="auto"
                          loading="lazy"
                        />
                      </AnimationFade>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="plans-warrap">
          <div id="plans" className="div-lines">
            <div className="lines text-center">
              <h1>{translation.plans}</h1>
            </div>
          </div>
          <div className="position-relative">
            <div className="left-plans-circle">
              <div className="left-plans-circle-div">
                <div className="left-plans-circle-details" />
              </div>
            </div>
            <div className="plans-section pt-5">
              <Container>
                <Row>
                  {plansData.map((item) => (
                    <Col key={item.id} xs={12} md={6} lg={4} xl={3}>
                      <div className={item.id === 1 ? "card1" : item.id === 2 ? "card2" : item.id === 3 ? "card3" : "card4"}>
                        <CustomCard
                          description={item.description}
                          name={item.name} price={item.price}
                          color={item.id === 1 ? "text-star" : item.id === 2 ? "text-star1" : item.id === 3 ? "text-danger1" : "text-secondary2"}
                          bg={item.id === 1 ? "star" : item.id === 2 ? "star1" : item.id === 3 ? "danger1" : "secondary2"}
                          per={item.sub_title}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
            {console.log('plansData', plansData)}
            <div className="right-plans-circle">
              <div className="right-plans-circle-div">
                <div className="right-plans-circle-details" />
              </div>
            </div>
            <div className="plans-details" />
            <div className="left-plans-small-circle">
              <div className="left-plans-small-circle-div">
                <div className="left-plans-small-circle-details" />
              </div>
            </div>
          </div>

          <div className="last-section" />
        </div>

      </div>
    </Layout>
  );
}

export default Home;

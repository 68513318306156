import { STORAGE_URL } from "configs";
import { useSharedAPIDataContext } from "hooks/useSharedAPIDataContext";
import React from "react";
import { Spinner } from "react-bootstrap";

const WebsiteLoader = () => {
  const { settings } = useSharedAPIDataContext();

  if (settings && !settings.header_secondary_icon) return;
  return (
    <div
      className="position-fixed top-0 left-0 right-0 bottom-0 w-100 h-100 d-flex flex-column gap-4 justify-content-center align-items-center text-white"
      style={{
        backgroundColor: "#fff",
      }}
    >
      {settings && settings.header_secondary_icon && <img src={STORAGE_URL + settings.header_secondary_icon} alt="logo" width={100} />}
      <Spinner animation="border" size="lg" className="text-primary"  />
    </div>
  );
};

export default WebsiteLoader;

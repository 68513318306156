import React from 'react';
import './Divider.scss';

function Divider() {
  return (
    <div className="divider-container">
      <div className="divider"></div>
    </div>
  );
}

export default Divider;
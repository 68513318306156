import { SharedAPIDataContext } from "contexts";
import { useContext } from "react";

export function useSharedAPIDataContext() {
  // ** Hooks
  const context = useContext(SharedAPIDataContext);

  // ** Throw Error if Context is not defined
  if (!context) {
    throw new Error("useSharedAPIDataContext must be used within a SharedAPIDataContextProvider");
  }

  // ** Destructure Context
  const { ...rest } = context;

  return {
    ...rest,
  };
}

import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Layout.scss";

function Layout({ children }) {
  return (
    <div id="layout">
      <Header />

      <div id="main">{children}</div>

      <Footer />
    </div>
  );
}

export default Layout;

import { API_TOKEN, API_URL } from "configs";

export async function fetchMultipleAPIs({
  endpoints,
  locale = "en",
  onSuccess = () => {},
}) {
  let data = {};

  let requestArr = [];
  requestArr.push(
    ...endpoints.map(async ({ method, name, endpoint, payload }) => {
      let url = new URL(API_URL + endpoint);

      if (method === "GET" && payload) {
        // set params for GET requests
        Object.keys(payload).forEach((key) => {
          url.searchParams.append(key, payload[key]);
        });
      }

      return fetch(url, {
        method,
        ...(method === "POST" && { body: payload }),
        headers: {
          "Content-Type": "application/json",
          Language: locale,
          token: API_TOKEN,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          data = {
            ...data,
            [name]: response.data,
          };
        })
        .catch((error) => console.log(error.toString()));
    })
  );

  await Promise.all(requestArr);

  if (!data) {
    console.warn("Error fetching static data'");
  }

  onSuccess(data);

  return data || {};
}
